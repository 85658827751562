import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import fr from './locales/fr.json';
import es from './locales/es.json';

// Detect browser language
const userLang = navigator.language || navigator.userLanguage;
//const detectedLang = userLang.startsWith('en') ? 'en' : userLang.startsWith('es') ? 'es' : 'fr';
const detectedLang = userLang.startsWith('en') ? 'fr' : userLang.startsWith('es') ? 'fr' : 'fr';

// Get saved locale from localStorage or fallback to detected language
const savedLocale = localStorage.getItem('locale') || detectedLang;

// Create the i18n instance
const i18n = createI18n({
  //locale: savedLocale, // Set the initial locale
  locale: 'fr', // Set the initial locale
  fallbackLocale: 'fr', // Fallback locale
  messages: {
    en,
    fr,
    es,
  },
});

// Save the language choice in localStorage
i18n.global.locale = savedLocale;

export default i18n;
