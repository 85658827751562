<template>
    <!-- Start Servics Style One 
    ============================================= -->
    <div class="services-style-one-area default-padding bg-gray">
        <div class="triangle-shape">
            <img src="@/assets/img/shape/10.png" alt="Shape">
        </div>
        <div class="center-shape shape5"></div>
        <div class="container">
            <div class="row align-center">
                <div class="col-lg-5 mb-md-60">
                    <div class="service-nav-info">
                        <h4 class="sub-title">Ce dans quoi j'excelle</h4>
                        <h2>Collaborer avec des esprits brillants autour d'un café et du code</h2>
                        <div class="nav nav-tabs service-tab-navs" id="nav-tab" role="tablist">

                            <button class="nav-link active" id="nav-id-1" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab" aria-controls="tab1" aria-selected="true">
                                <i class="fas fa-lightbulb"></i>
                                Analyser & Stratégiser <i class="fa fa-arrow-right small"></i>
                            </button>
                            <button class="nav-link" id="nav-id-2" data-bs-toggle="tab" data-bs-target="#tab2" type="button" role="tab" aria-controls="tab2" aria-selected="false">
                                <i class="fas fa-cogs"></i>
                                Concevoir & Développer <i class="fa fa-arrow-right small"></i>
                            </button>
                            <button class="nav-link" id="nav-id-3" data-bs-toggle="tab" data-bs-target="#tab3" type="button" role="tab" aria-controls="tab3" aria-selected="false">
                                <i class="fas fa-box"></i>
                                Mettre en œuvre & Optimiser <i class="fa fa-arrow-right small"></i>
                            </button>

                        </div>
                    </div>
                </div>
                <div class="col-lg-7 pl-50 pl-md-15 pl-xs-15">
                    <div class="tab-content services-tab-content" id="nav-tabContent">

                        <!-- Tab Single Item -->
                        <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="nav-id-1">
                            <div class="row">
                                <!-- Single Item -->
                                <div class="col-lg-6 col-md-6 mt-60 mt-md-30 mt-xs-30 wow fadeInUp">
                                    <div class="services-style-one">
                                        <i class="fas fa-tasks"></i>
                                        <h4><a href="#">Évaluation des Capacités</a></h4>
                                        <p>
                                            Analyse complète des systèmes et processus actuels pour identifier les forces et faiblesses, garantissant une base solide pour le développement futur.
                                        </p>
                                    </div>
                                </div>
                                <!-- End Single Item -->
                                 <!-- Single Item -->
                                 <div class="col-lg-6 col-md-6 mt-60 mt-md-30 mt-xs-30 wow fadeInUp" data-wow-delay="300ms">
                                    <div class="services-style-one">
                                        <i class="fas fa-binoculars"></i>
                                        <h4><a href="#">Vision à Long Terme</a></h4>
                                        <p>
                                            Définir une stratégie technologique alignée avec les objectifs d'affaires à long terme, assurant une croissance durable et compétitive.
                                        </p>
                                    </div>
                                </div>
                                <!-- End Single Item -->
                                <!-- Single Item -->
                                <div class="col-lg-6 col-md-6 mt-60 mt-md-30 mt-xs-30 wow fadeInUp" data-wow-delay="500ms">
                                    <div class="services-style-one">
                                        <i class="fas fa-shield-alt"></i>
                                        <h4><a href="#">Audit des Risques</a></h4>
                                        <p>
                                            Identification proactive des risques potentiels liés aux initiatives technologiques et produit, accompagnée de plans d'atténuation robustes pour sécuriser les opérations.
                                        </p>
                                    </div>
                                </div>
                                <!-- End Single Item -->
                                 <!-- Single Item -->
                                 <div class="col-lg-6 col-md-6 mt-60 mt-md-30 mt-xs-30 wow fadeInUp" data-wow-delay="700ms">
                                    <div class="services-style-one">
                                        <i class="fas fa-map"></i>
                                        <h4><a href="#">Planification Stratégique Globale</a></h4>
                                        <p>
                                            Conception de feuilles de route intégrées qui harmonisent les objectifs commerciaux avec les innovations technologiques, facilitant une exécution sans faille.
                                        </p>
                                    </div>
                                </div>
                                <!-- End Single Item -->
                            </div>
                        </div>
                        <!-- End Tab Single Item -->

                        <!-- Tab Single Item -->
                        <div class="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="nav-id-2">
                            <div class="row">
                                <!-- Single Item -->
                                <div class="col-lg-6 col-md-6 mt-60 mt-md-30 mt-xs-30">
                                    <div class="services-style-one">
                                        <i class="fas fa-users"></i>
                                        <h4><a href="#">Design Centré sur l'Utilisateur</a></h4>
                                        <p>
                                            Création de solutions innovantes en mettant l'accent sur l'expérience utilisateur, garantissant des produits qui répondent aux besoins réels du marché.
                                        </p>
                                    </div>
                                </div>
                                <!-- End Single Item -->
                                <!-- Single Item -->
                                <div class="col-lg-6 col-md-6 mt-60 mt-md-30 mt-xs-30">
                                    <div class="services-style-one">
                                        <i class="fas fa-cubes"></i>
                                        <h4><a href="#">Architecture et Scalabilité</a></h4>
                                        <p>
                                            Conception d'architectures systèmes modulaires et évolutives, prêtes à accueillir la croissance future sans compromis sur la performance ou la sécurité.
                                        </p>
                                    </div>
                                </div>
                                <!-- End Single Item -->
                                <!-- Single Item -->
                                <div class="col-lg-6 col-md-6 mt-60 mt-md-30 mt-xs-30">
                                    <div class="services-style-one">
                                        <i class="fas fa-robot"></i>
                                        <h4><a href="#">Technologies de Pointes</a></h4>
                                        <p>
                                            Adoption des technologies émergentes telles que l'intelligence artificielle et l'automatisation, pour propulser votre entreprise à l'avant-garde de l'innovation.
                                        </p>
                                    </div>
                                </div>
                                <!-- End Single Item -->
                                <!-- Single Item -->
                                <div class="col-lg-6 col-md-6 mt-60 mt-md-30 mt-xs-30">
                                    <div class="services-style-one">
                                        <i class="fas fa-flask"></i>
                                        <h4><a href="#">Prototypage et MVP</a></h4>
                                        <p>
                                            Développement de prototypes et de produits minimum viables (MVP) pour tester rapidement les idées, réduire les risques et valider les concepts avant le lancement.
                                        </p>
                                    </div>
                                </div>
                                <!-- End Single Item -->
                            </div>
                        </div>
                        <!-- End Tab Single Item -->

                        <!-- Tab Single Item -->
                        <div class="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="nav-id-3">
                            <div class="row">
                                <!-- Single Item -->
                                <div class="col-lg-6 col-md-6 mt-60 mt-md-30 mt-xs-30">
                                    <div class="services-style-one">
                                        <i class="fas fa-check-circle"></i>
                                        <h4><a href="#">Exécution Rigoureuse</a></h4>
                                        <p>
                                            Mise en œuvre précise des stratégies technologiques et produit, avec un suivi rigoureux pour garantir le respect des délais et des budgets.
                                        </p>
                                    </div>
                                </div>
                                <!-- End Single Item -->
                                <!-- Single Item -->
                                <div class="col-lg-6 col-md-6 mt-60 mt-md-30 mt-xs-30">
                                    <div class="services-style-one">
                                        <i class="fas fa-sync-alt"></i>
                                        <h4><a href="#">Optimisation Continue</a></h4>
                                        <p>
                                            Amélioration constante des systèmes et processus à travers un retour d'expérience continu, assurant une performance optimale à chaque étape du cycle de vie du produit.
                                        </p>
                                    </div>
                                </div>
                                <!-- End Single Item -->
                                <!-- Single Item -->
                                <div class="col-lg-6 col-md-6 mt-60 mt-md-30 mt-xs-30">
                                    <div class="services-style-one">
                                        <i class="fas fa-tools"></i>
                                        <h4><a href="#">Support et Maintenance Proactive</a></h4>
                                        <p>
                                            Fournir un support continu et une maintenance proactive pour assurer la fiabilité des systèmes, minimiser les temps d'arrêt et maximiser la satisfaction client.
                                        </p>
                                    </div>
                                </div>
                                <!-- End Single Item -->
                                <!-- Single Item -->
                                <div class="col-lg-6 col-md-6 mt-60 mt-md-30 mt-xs-30">
                                    <div class="services-style-one">
                                        <i class="fas fa-comments"></i>
                                        <h4><a href="#">Communication Transparente</a></h4>
                                        <p>
                                            Facilitation d'une communication fluide entre les équipes pluri-disciplinaires, pour garantir une compréhension partagée des objectifs et une collaboration efficace.
                                        </p>
                                    </div>
                                </div>
                                <!-- End Single Item -->
                            </div>
                        </div>
                        <!-- End Tab Single Item -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Services Style One -->
  </template>
  
  <script>
  export default {
    name: 'ServicesSection',
  };
  </script>
  
  <style scoped>
  .shape5 {
    background-image: url(@/assets/img/shape/5.png);
  }
  .service-tab-navs .nav-link {
    padding: 10px 15px
  }
  .small {
    font-size: 14px !important;
    color: grey;  
}
  </style>
  