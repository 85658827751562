<template>
    <TopBar />
    <SiteHeader />
    <MainBanner />
    <ProcessSection />
    <WhoSection />
    <TestimonialSection />
    <CallAction />
    <FaqSection />
    <CookieConsent />
    <SiteFooter />
</template>
  
  <script>
  import TopBar from '@/components/TopBar.vue';
  import SiteHeader from '@/components/SiteHeader.vue';
  import MainBanner from '@/components/MainBanner.vue';
  import ProcessSection from '@/components/ProcessSection.vue';
  import WhoSection from '@/components/WhoSection.vue';
  import TestimonialSection from '@/components/TestimonialSection.vue';
  import FaqSection from '@/components/FaqSection.vue';
  import CallAction from '@/components/CallAction.vue';
  import CookieConsent from '@/components/CookieConsent.vue';
  import SiteFooter from '@/components/SiteFooter.vue';
  
  export default {
    components: {
      TopBar,
      SiteHeader,
      MainBanner,
      ProcessSection,
      WhoSection,
      TestimonialSection,
      FaqSection,
      CallAction,
      CookieConsent,
      SiteFooter,
    },
    /*
    metaInfo() {
      console.log('Setting meta info:', this.$route.meta);
      return {
        title: this.$route.meta.title || 'Michael James PORTER',
        meta: [
          { name: 'description', content: this.$route.meta.description || 'Michael James PORTER' },
          { name: 'keywords', content: this.$route.meta.keywords || 'Consultant en startup, CTO, Chief Technology Officer, CPO, Chief Product Officer, Alignement technologique' },
          { property: 'og:title', content: this.$route.meta.ogTitle || 'Michael James PORTER' },
          { property: 'og:description', content: this.$route.meta.ogDescription || 'Michael James PORTER' },
          { property: 'og:image', content: this.$route.meta.ogImage || '/assets/img/ogImage.jpg' },
          { property: 'og:url', content: this.$route.meta.ogUrl || 'https://michael-james-porter.com' },
          { property: 'og:type', content: 'website' },
        ]
      };
    }*/
  };
  </script>
  