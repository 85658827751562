<template>
  <TopBar />
  <SiteHeader />
  <BreadCrumbs :pageTitle="article?.title" />

      <!-- Star Services Details Area
    ============================================= -->
    <div class="overflow-hidden services-details-area default-padding">
        <div class="container" v-if="article">
            <div class="services-details-items">
                <div class="row">
                    
                    <div class="col-xl-8 col-lg-7 order-lg-last pl-50 pl-md-15 pl-xs-15">
                      <br />
                        <h1>{{ article.title }}</h1>
                        
    <!-- Display content dynamically -->
    <section v-if="article?.content?.length">
      <div v-for="(block, index) in article.content" :key="index">
        <!-- Handle paragraphs -->
        <p v-if="block.type === 'paragraph' && block.children">
          <span v-for="(child, childIndex) in block.children" :key="childIndex">
            {{ child.text }}
          </span>
        </p>
        
        <!-- Handle ordered lists -->
        <ol v-if="block.type === 'list' && block.format === 'ordered'">
          <li v-for="(listItem, listIndex) in block.children" :key="listIndex">
            <span v-for="(listChild, listChildIndex) in listItem.children" :key="listChildIndex">
              <strong v-if="listChild.bold">{{ listChild.text }}</strong>
              <span v-else>{{ listChild.text }}</span>
            </span>
          </li>
        </ol>
      </div>
    </section>
            
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- End Services Details Area -->

    <CookieConsent />
  <SiteFooter />
</template>

<script>
  import TopBar from '@/components/TopBar.vue';
  import SiteHeader from '@/components/SiteHeader.vue';
  import BreadCrumbs from '@/components/BreadCrumbs.vue';
  import CookieConsent from '@/components/CookieConsent.vue';
  import SiteFooter from '@/components/SiteFooter.vue';
  import axios from 'axios';
  
  export default {
    name: 'ArticleHowTo',
    components: {
      TopBar,
      SiteHeader,
      BreadCrumbs,
      CookieConsent,
      SiteFooter,
    },
    data() {
      return {
        article: null,
      };
    },
    async created() {
      const slug = this.$route.params.slug;
      try {
        const response = await axios.get('https://transferstation.ealliance.trade:1338/api/legals', {
          params: {
            'filters[slug][$eq]': slug,
          },
          headers: {
            Authorization: `Bearer f665fa877cc16eae8d3eb504a50e76c61c6a53d31da77957d823cda87428c512946895a215f7c0b44e776f04fc9ed82ac04860fda42c441300d9479bf544166297dbb7fa9fdbcc2ac5c8ddaf603f55958d576ece59251800a9b00fff8d9387ca82ea24fc537375b905fbdfd7274901a42e771360b441e6f41b43099b9b99ab77`
          }
        });
        if (response.data.data.length > 0) {
          this.article = response.data.data[0].attributes;
        }
      } catch (error) {
        console.error('Error fetching article:', error);
      }
    },
  };
  </script>
  <style scoped>
  .default-padding {
    padding-top: 60px !important;
  }
.ctabk {
  background-image: url(@/assets/img/shape/15.png);
}
ol {
  margin-left: 20px;
  list-style-type: decimal;
}
  </style>