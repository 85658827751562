<template>
        <!-- Start Banner Area 
    ============================================= -->
    <div class="overflow-hidden shadow banner-area banner-style-one navigation-custom-large zoom-effect text-light">
        <!-- Slider main container -->
        <div class="banner-fade swiper-container">
            <!-- Additional required wrapper -->
            <div class="swiper-wrapper">

                <!-- Single Item 
                <div class="swiper-slide banner-style-one">
                    <div class="bg-cover shadow banner-thumb dark" style="background: url(assets/img/2440x1578.jpg);"></div>
                    <div class="container">
                        <div class="row align-center">
                            <div class="col-xl-7 offset-xl-5">
                                <div class="content">
                                    <h4>Meet Consulting</h4>
                                    <h2><strong>Financial Analysis</strong> Developing Meeting.</h2>
                                    <div class="mt-40 button">
                                        <a class="btn-animation" href="#"><i class="fas fa-arrow-right"></i> <span>Our Services</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="banner-shape-bg">
                        <img src="assets/img/shape/4.png" alt="Shape">
                    </div>
                </div>
                 End Single Item -->

                <!-- Single Item -->
                <div id="slide-1" class="swiper-slide banner-style-one">
                    <!-- Video Background -->
                    <video id="video-1" class="bg-cover shadow banner-thumb dark vid" autoplay muted loop>
                        <source :src="require('@/assets/videos/startup2.mp4')" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                    <div class="container">
                        <div class="row align-center">
                            <div class="col-xl-7 offset-xl-5">
                                <div class="content">
                                    <h4>{{ $t('banner.slide1.h1') }}</h4>
                                    <h2>{{ $t('banner.slide1.h2') }}</h2>
                                    <div class="mt-40 button">
                                        <a class="btn-animation" href="/startups/article/premieres-etapes"><i class="fas fa-arrow-right"></i> <span>{{ $t('banner.slide1.button') }}</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Shape -->
                    <div class="banner-shape-bg">
                        <img src="@/assets/img/shape/4.png" alt="Shape">
                    </div>
                    <!-- End Shape -->
                </div>
                <!-- End Single Item -->

                <!-- Single Item -->
                <div id="slide-2" class="swiper-slide banner-style-one">
                    <!-- Video Background -->
                    <video id="video-2" class="bg-cover shadow banner-thumb dark vid" autoplay loop muted>
                        <source :src="require('@/assets/videos/idea2.mp4')" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                    <div class="container">
                        <div class="row align-center">
                            <div class="col-xl-7 offset-xl-5">
                                <div class="content">
                                    <h4>{{ $t('banner.slide2.h1') }}</h4>
                                    <h2>{{ $t('banner.slide2.h2') }}</h2>
                                    <div class="mt-40 button">
                                        <a class="btn-animation" href="/startups/article/vision-vs-execution"><i class="fas fa-arrow-right"></i> <span>{{ $t('banner.slide2.button') }}</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Shape -->
                    <div class="banner-shape-bg">
                        <img src="@/assets/img/shape/4.png" alt="Shape">
                    </div>
                    <!-- End Shape -->
                </div>
                <!-- End Single Item -->

                <!-- Single Item -->
                <div id="slide-3" class="swiper-slide banner-style-one">
                    <!-- Video Background -->
                    <video id="video-3" class="bg-cover shadow banner-thumb dark vid" autoplay loop muted>
                        <source :src="require('@/assets/videos/scale-up2.mp4')" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                    <div class="container">
                        <div class="row align-center">
                            <div class="col-xl-7 offset-xl-5">
                                <div class="content">
                                    <h4>{{ $t('banner.slide3.h1') }}</h4>
                                    <h2>{{ $t('banner.slide3.h2') }}</h2>
                                    <div class="mt-40 button">
                                        <a class="btn-animation" href="/startups/article/grandir-en-confiance"><i class="fas fa-arrow-right"></i> <span>{{ $t('banner.slide3.button') }}</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Shape -->
                    <div class="banner-shape-bg">
                        <img src="@/assets/img/shape/4.png" alt="Shape">
                    </div>
                    <!-- End Shape -->
                </div>
                <!-- End Single Item -->

                <!-- Single Item  -->
                <div id="slide-4" class="swiper-slide banner-style-one">
                    <div class="bg-cover shadow banner-thumb dark tec"></div>
                    <div class="container">
                        <div class="row align-center">
                            <div class="col-xl-7 offset-xl-5">
                                <div class="content">
                                    <h4>{{ $t('banner.slide4.h1') }}</h4>
                                    <h2>{{ $t('banner.slide4.h2') }}</h2>
                                    <div class="mt-40 button">
                                        <a class="btn-animation" href="/startups/article/innover-avec-avenir-en-tete"><i class="fas fa-arrow-right"></i> <span>{{ $t('banner.slide4.button') }}</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Shape  -->
                    <div class="banner-shape-bg">
                        <img src="@/assets/img/shape/4.png" alt="Shape">
                    </div>
                    <!-- End Shape -->
                </div>
                <!-- End Single Item -->

                <!-- Single Item  -->
                <div id="slide-5" class="swiper-slide banner-style-one">
                    <!-- Video Background -->
                    <video id="video-5" class="bg-cover shadow banner-thumb dark vid" autoplay loop muted >
                        <source :src="require('@/assets/videos/team2.mp4')" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                    <div class="container">
                        <div class="row align-center">
                            <div class="col-xl-7 offset-xl-5">
                                <div class="content">
                                    <h4>{{ $t('banner.slide5.h1') }}</h4>
                                    <h2>{{ $t('banner.slide5.h2') }}</h2>
                                    <div class="mt-40 button">
                                        <a class="btn-animation" href="/startups/article/competence-des-equipes"><i class="fas fa-arrow-right"></i> <span>{{ $t('banner.slide5.button') }}</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Shape  -->
                    <div class="banner-shape-bg">
                        <img src="@/assets/img/shape/4.png" alt="Shape">
                    </div>
                    <!-- End Shape -->
                </div>
                <!-- End Single Item -->

            </div>

            <!-- Pagination -->
            <div class="swiper-pagination"></div>

        </div>  
    </div>
    <!-- End Main -->
</template>
  
<script>

</script>
  
  <style scoped>
  .tec {
    background: url(@/assets/img/tec.jpg);
  }
  </style>
  