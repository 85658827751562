<template>
        <!-- Start Testimonials 
    ============================================= -->
    <div class="testimonial-style-one-area default-padding">
        <div class="container">
            <div class="row align-center">

                <div class="col-lg-4">
                    <a href="https://www.linkedin.com/in/michael-james-porter-3705787b/recommendations/" target="_blank"><img src="@/assets/img/recom.jpg" alt="illustration"></a>
                    <!--
                    <div class="testimonial-thumb">
                        <div class="thumb-item">
                            
                            <div class="mini-shape">
                                <img src="assets/img/shape/19.png" alt="illustration">
                            </div>
                        </div>
                    </div>-->
                </div>

                <div class="col-lg-7 offset-lg-1">
                    <div class="testimonial-carousel swiper">
                        <!-- Additional required wrapper -->
                        <div class="swiper-wrapper">
                            <!-- Single item -->
                            <div class="swiper-slide">
                                <div class="testimonial-style-one">
                                    
                                    <div class="item">
                                        <div class="content">
                                            <p>
                                                “I had an enjoyable time working with Michael at Icare, both professionally and personally. 
                                                Michael has an in-depth technology knowledge with a strong ability to quickly define the key milestones and anticipate possible hurdles/setbacks to achieve what is required. 
                                                Michael understands the business stakes in all projects and identify the best options to create value across the board. Last but not least, Michael has both the patience and the passion for technology to make tech understandable to everyone which strengthen the collaborative dynamique across teams.”
                                            </p>
                                        </div>
                                        <a href="https://www.linkedin.com/in/guillaume-marcilhac?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_recommendations_details%3B6qlwzMdRSS2YJNL%2F%2F0DhCg%3D%3D" target="_blank">
                                            <div class="provider">
                                                <img src="https://media.licdn.com/dms/image/v2/D4E03AQFCE6tpr1wybQ/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1707403177474?e=1730937600&v=beta&t=-XlvI1sFedNAHi2c6xLqopjeYA1wKElflqKkxCEZ89E">
                                                <div class="info">
                                                    <h4>Guillaume Marcilhac @ INSEAD</h4>
                                                    <span>COO - CRO Tech startup & Scale up Leadership I Attitude I Adaptability I Performance I Scale</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- End Single item -->
                            <!-- Single item -->
                            <div class="swiper-slide">
                                <div class="testimonial-style-one">
                                    <div class="item">
                                        <div class="content">
                                            <p>
                                                “C'est avec plaisir que je recommande Michaël James Porter en tant que collaborateur, Co-fondateur et CTPO d'EALLIANCE. Il a montré un leadership visionnaire en guidant l'équipe dans la création d'un écosystème blockchain innovant, apportant des solutions concrètes aux défis du transfert d'argent mondial. Sa capacité à concilier les normes européennes avec le marché africain est remarquable. Michaël est un visionnaire technologique et un leader exceptionnel.”
                                            </p>
                                        </div>
                                        <a href="https://www.linkedin.com/in/isaac-kedohounsi-5623a712a?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_recommendations_details%3B6qlwzMdRSS2YJNL%2F%2F0DhCg%3D%3D" target="_blank">
                                            <div class="provider">
                                                <img src="https://media.licdn.com/dms/image/v2/C5603AQFdX4zADrNH5g/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1654849842351?e=1730937600&v=beta&t=icqpdKOLq_nTUNjebUZtByt69DEYE7l_kW3utvZ4_So">
                                                <div class="info">
                                                    <h4>ISAAC KEDOHOUNSI @ EALLIANCE GROUP</h4>
                                                    <span>Co-founder & CEO</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- End Single item -->
                            <!-- Single item -->
                            <div class="swiper-slide">
                                <div class="testimonial-style-one">
                                    <div class="item">
                                        <div class="content">
                                            <p>
                                                “J'ai eu la chance de travailler aux côtés de Michael, CTO chez Icare, au cours des 18 derniers mois.
                                                Michael dispose d’une compréhension approfondie de son domaine, conséquence d’une curiosité illimitée et d’une vaste culture générale et technique.
                                                Proactif pour rester à l'affût des dernières avancées technologiques (IA…), Michael est non seulement un professionnel compétent, mais également un collègue à l’écoute qui valorise la collaboration inter-équipes. 
                                                Si vous appréciez de travailler avec quelqu'un qui combine expertise technique, ouverture d'esprit et une touche d'humour (anglais bien sûr !), je vous recommande vivement Michael !”
                                            </p>
                                        </div>
                                        <a href="https://www.linkedin.com/in/jacinthepostille?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_recommendations_details%3B6qlwzMdRSS2YJNL%2F%2F0DhCg%3D%3D" target="_blank">
                                            <div class="provider">
                                                <img src="https://media.licdn.com/dms/image/v2/C5603AQG6qxu1SVuI5Q/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1547978839976?e=1730937600&v=beta&t=Q63-5CJu_lvkhxgzfvlKbQ7ZrqGmhHu1LEzFpU7n9qU">
                                                <div class="info">
                                                    <h4>Jacinthe Postille @ ITrust - Iliad Group</h4>
                                                    <span>Directrice Marketing et Communication</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- End Single item -->
                            <!-- Single item -->
                            <div class="swiper-slide">
                                <div class="testimonial-style-one">
                                    <div class="item">
                                        <div class="content">
                                            <p>
                                                “Michael's strengths lie in his exceptional pedagogical skills, genuine care for his team, and unwavering respect for others. As our CTO, he made complex technical concepts accessible to all team members, fostering a culture of continuous learning. His caring leadership style and commitment to creating a respectful work environment significantly contributed to our team's success.
                                                It was a pleasure working with Michael, and I'm confident that his unique blend of leadership, caring nature, and respect for others will continue to make a positive impact in any professional setting.”
                                            </p>
                                        </div>
                                        <a href="https://www.linkedin.com/in/amandine-briano?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_recommendations_details%3B6qlwzMdRSS2YJNL%2F%2F0DhCg%3D%3D" target="_blank">
                                            <div class="provider">
                                                <img src="https://media.licdn.com/dms/image/v2/D4E03AQHPlhYazO7oSg/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1708081144676?e=1730937600&v=beta&t=ffE-ne6J1txh6JYi918mYfTnWnYvYQwIMZE-t4cDyHA">
                                                <div class="info">
                                                    <h4>Amandine Briano @ people&baby</h4>
                                                    <span>Directrice commerciale grands comptes</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- End Single item -->
                            <!-- Single item -->
                            <div class="swiper-slide">
                                <div class="testimonial-style-one">
                                    <div class="item">
                                        <div class="content">
                                            <p>
                                                “I am pleased to recommend Michael James Porter, with whom I had the privilege of collaborating at ealliance and Insurance Buffalo. More than a co-worker, he's a friend whose positive demeanor fosters a productive environment. Michael’s proactive approach and exceptional communication skills consistently deliver outstanding results. He is the go-to professional for any challenge, demonstrating thoughtful leadership and expertise. I wholeheartedly endorse him for any role.”
                                            </p>
                                        </div>
                                        <a href="https://www.linkedin.com/in/obed-klein?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_recommendations_details%3B6qlwzMdRSS2YJNL%2F%2F0DhCg%3D%3D" target="_blank">
                                            <div class="provider">
                                                <img src="https://media.licdn.com/dms/image/v2/C4D03AQGT_F9PktQizA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1645102156505?e=1730937600&v=beta&t=GQtlTSWs2W8YwU34Ijryjc4gA-EfNlvojdi4GpaIdQU">
                                                <div class="info">
                                                    <h4>Obed Klein @ The Buffalo Company</h4>
                                                    <span>Founder & CEO</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- End Single item -->
                            <!-- Single item -->
                            <div class="swiper-slide">
                                <div class="testimonial-style-one">
                                    <div class="item">
                                        <div class="content">
                                            <p>
                                                “I had the privilege of working under Michael's leadership when he was CTO. He excels at leading teams to success with strategic insight and a deep understanding of business and technology. His communication skills, both internally and externally, foster lasting collaborations. Michael creates a motivating and respectful environment, sharing his knowledge and supporting team growth. In short, Michael is a transformative leader who turns challenges into opportunities.”
                                            </p>
                                        </div>
                                        <a href="https://www.linkedin.com/in/fadi-jabroun?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_recommendations_details%3B6qlwzMdRSS2YJNL%2F%2F0DhCg%3D%3D" target="_blank">
                                            <div class="provider">
                                                <img src="https://media.licdn.com/dms/image/v2/D4E03AQHWOyoz5R53Kw/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1703490417982?e=1730937600&v=beta&t=Xgb3eWcmw0LHIYe7yZetXUBn44REj3wG-AxrWfdUtmU">
                                                <div class="info">
                                                    <h4>Fadi Jabroun @ ICARE GROUP</h4>
                                                    <span>Engineering Manager</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- End Single item -->
                            <!-- Single item -->
                            <div class="swiper-slide">
                                <div class="testimonial-style-one">
                                    <div class="item">
                                        <div class="content">
                                            <p>
                                                “It was a privilege to work alongside Michael at ICARE, and I am enthusiastic to endorse his exceptional technological expertise and leadership. His strategic vision and ability to navigate through complex technological challenges were pivotal to our projects' success. Michael stands out not only for his technical skills but also for his ability to motivate and inspire his team, fostering an innovative and productive work environment. His contributions have significantly impacted our growth and efficiency, making him an invaluable asset to any organization. I am confident that Michael will continue to bring excellence and innovation to any new challenges he undertakes.”
                                            </p>
                                        </div>
                                        <a href="https://www.linkedin.com/in/adrien-e-508906161?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_recommendations_details%3B6qlwzMdRSS2YJNL%2F%2F0DhCg%3D%3D" target="_blank">
                                            <div class="provider">
                                                <img src="https://media.licdn.com/dms/image/v2/D4E03AQF0O5aclIQH8g/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1702998950603?e=1730937600&v=beta&t=DIzRH7yF5uoEWry2IRRCg-wO1pdK2vY0Kxx6rfXIu8o">
                                                <div class="info">
                                                    <h4>Adrien ETIENNE @ Dailymotion </h4>
                                                    <span>Senior Software Engineer</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- End Single item -->
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- End Testimonails  -->
  </template>
  
  <script>
  export default {
    name: 'TestimonialSection',
  };
  </script>
  
  <style scoped>
    .provider img {
        border-radius: 100px;
        margin-right: 10px;
    }
  </style>
  