<template>
        <!-- Start Faq 
    ============================================= -->
    <div class="relative faq-style-one-area shape32">

        
<div class="container">
    <div class="row align-center">

        <div class="col-lg-6">
            <div class="faq-style-one default-padding">
                <h4 class="sub-heading">Basic faq</h4>
                <h2 class="title mb-30">Common Questions</h2>
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Comment savoir si ma startup a besoin d'un CTO ou d'un CPO ?
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>
                                    Si vous avez besoin d'aide pour concrétiser votre vision produit, un CTO ou un CPO peut fournir l'orientation stratégique nécessaire. Je peux commencer par prendre des responsabilités clés, puis former et développer votre équipe à mesure que l'entreprise grandit.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Quels qualités dois-je prioriser lors du recrutement d'un CTO ou d'un CPO ?
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>
                                    Recherchez une personne ayant une expérience avérée en stratégie et en exécution. Ils doivent savoir diriger des équipes, innover et aligner la technologie ou le développement produit avec vos objectifs commerciaux. Mon approche inclut la construction d'une base solide et l'évolution progressive de l'équipe pour une croissance durable.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Comment un CTO ou un CPO peut-il aider mon entreprise à se développer ?
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>
                                    Un CTO peut garantir que votre infrastructure technologique est robuste, évolutive et sécurisée, tandis qu'un CPO peut affiner votre feuille de route produit pour répondre aux demandes du marché en évolution. Je commencerai par répondre à vos besoins immédiats, puis développerai et formerai votre équipe au fur et à mesure que votre entreprise évolue.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Quelles sont les premières étapes qu'un CTO ou CPO doit prendre dans mon entreprise ?
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>
                                    Je commencerai par évaluer votre infrastructure technologique ou votre portefeuille produit actuel pour identifier les lacunes et les opportunités. Ensuite, je développerai une feuille de route stratégique, prioriserai les initiatives clés et alignerai les ressources nécessaires.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="heading9">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                                Comment un CTO ou un CPO peut-il aider à lever des fonds et entretenir des relations avec les investisseurs ?
                            </button>
                        </h2>
                        <div id="collapse9" class="accordion-collapse collapse" aria-labelledby="heading9" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>
                                    Je peux articuler votre vision technologique ou produit de manière à résonner avec les investisseurs, en montrant comment votre entreprise est prête à croître. Mon approche consiste à commencer avec une petite équipe ciblée, puis à évoluer en fonction des besoins, ce qui peut également séduire les investisseurs à la recherche d'une croissance durable et stratégique.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-5 offset-lg-1 mt-120 mt-md-50 mt-xs-30">
            <div class="faq-thumb">
                <img src="@/assets/img/illustration/6.png" alt="Image Not Found">
            </div>
        </div>

    </div>
</div>
</div>
<!-- End Faq -->
  </template>
  
  <script>
  export default {
    name: 'FaqSection',
  };
  </script>
  
  <style scoped>
  .shape32 {
    background-image: url(@/assets/img/shape/32.png);
  }
  </style>
  