<template>
    <!-- Start Breadcrumb 
    ============================================= -->
    <div class="text-center bg-cover shadow breadcrumb-area dark text-light generic">
        <div class="breadcrum-shape">
            <img src="@/assets/img/shape/50.png" alt="Image Not Found">
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <h1>{{ translatedTitle }}</h1>
                    <ul class="breadcrumb">
                        <li>
                            <router-link to="/"><i class="fas fa-home"></i> {{ $t('Home') }}</router-link>
                        </li>
                        <li>{{ translatedTitle }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- End Breadcrumb -->
</template>

<script>
    export default {
        name: 'BreadCrumbs',
        props: {
            pageTitle: {
                type: String,
                required: true,
            },
            /*
            slug: {
                type: String,
                default: '',  // If you want to accept 'slug', declare it here
            },*/
        },
        computed: {
            translatedTitle() {
                return this.pageTitle || this.$t(this.$route.name);
            },
        },
    };
</script>

<style scoped>
    .generic {
        background-image: url(@/assets/img/generic.jpg);
    }
    .breadcrumb-area{
        padding: 50px 0;
        padding-top: 120px;
    }
</style>
