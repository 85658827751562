<template>
  <!-- Start Request Call Back 
    ============================================= -->
    <div class="request-call-back-area text-light default-padding cta">
        <div class="container">
            <div class="row align-center">
                <div class="col-lg-6">
                    <h2 class="title">Pour favoriser la réussite de votre projet en toute sérénité</h2>
                    <a class="btn circle btn-light mt-30 mt-md-15 mt-xs-10 btn-md radius animation" href="/contact">Contactez-moi</a>
                </div>
                <div class="col-lg-6 text-end">
                    <!--
                    <div class="achivement-counter">
                        <ul>
                            <li>
                                <div class="icon">
                                    <i class="flaticon-handshake"></i>
                                </div>
                                <div class="fun-fact">
                                    <div class="counter">
                                        <div class="timer" data-to="500" data-speed="2000">500</div>
                                        <div class="operator">+</div>
                                    </div>
                                    <span class="medium">Business advices given over 30 years</span>
                                </div>
                            </li>
                            <li>
                                <div class="icon">
                                    <i class="flaticon-employee"></i>
                                </div>
                                <div class="fun-fact">
                                    <div class="counter">
                                        <div class="timer" data-to="30" data-speed="2000">30</div>
                                        <div class="operator">+</div>
                                    </div>
                                    <span class="medium">Business Excellence awards achieved</span>
                                </div>
                            </li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <!-- End Request Call Back  -->
  </template>
  
  <script>
  export default {
    name: 'CallAction',
  };
  </script>
  
  <style scoped>
    .cta {
        background-image: url(@/assets/img/dawn.jpg);
    }
  </style>
  