<template>
  <TopBar />
  <SiteHeader />
  <BreadCrumbs :pageTitle="article?.title || 'Loading...'" />

      <!-- Star Services Details Area
    ============================================= -->
    <div class="overflow-hidden services-details-area default-padding">
        <div class="container" v-if="article">
            <div class="services-details-items">
                <div class="row">
                    
                    <div class="col-xl-8 col-lg-7 order-lg-last pl-50 pl-md-15 pl-xs-15">
                      <br />
                        <h1>{{ article.title }}</h1>
                        <section v-if="article?.introduction?.length">
                            <div v-for="(paragraph, index) in article.introduction" :key="index">
                              <p v-if="paragraph.type === 'paragraph'">{{ paragraph.children[0]?.text }}</p>
                            </div>
                        </section>
                        <!-- Introduction Quotes -->
                        <div class="quote-section">
                          <div v-for="(quote, index) in filteredIntroQuotes" :key="index" class="quote">
                            <p v-for="(quotePart, quoteIndex) in filteredQuoteText(quote)" :key="quoteIndex">
                              {{ quotePart.text }}
                              —Source : <a v-if="quoteLink(quote)" :href="quoteLink(quote).url" target="_blank" rel="noopener">{{ quoteLink(quote).children[0]?.text }}</a>
                            </p>
                            
                          </div>
                        </div>
            
                        <div class="mt-40 features mt-xs-30 mb-30 mb-xs-20">
                            <div class="row">
                                <div class="col-lg-5 col-md-6">
                                    <div class="content">
                                    <!-- Objectives -->
                                    <section v-if="article.objectives?.length">
                                      <h3>Objectifs Clés</h3>
                                      <ul v-if="article.objectives[0]?.format === 'ordered'" class="feature-list-item">
                                        <li v-for="(item, index) in article.objectives[0]?.children" :key="index">
                                          {{ item.children[0]?.text }}
                                        </li>
                                      </ul>
                                    </section>
                                    </div>
                                </div>
                                <div class="col-lg-7 col-md-6 mt-xs-30">
                                    <div class="content">
                                        <h3>Les Défis</h3>
                                        <section v-if="article?.challenges?.length">
                                            <div v-for="(paragraph, index) in article.challenges" :key="index">
                                              <p v-if="paragraph.type === 'paragraph'">{{ paragraph.children[0]?.text }}</p>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <h3>Stratégies de Réussite</h3>
                        <section v-if="article?.strategies?.length">
                            <div v-for="(paragraph, index) in article.strategies" :key="index">
                              <p v-if="paragraph.type === 'paragraph'">{{ paragraph.children[0]?.text }}</p>
                            </div>
                        </section>
                        <!-- Strategies Quotes -->
                        <div class="quote-section">
                          <div v-for="(quote, index) in filteredStrategyQuotes" :key="index" class="quote">
                            <p v-for="(quotePart, quoteIndex) in filteredQuoteText(quote)" :key="quoteIndex">
                              {{ quotePart.text }}
                              —Source : <a v-if="quoteLink(quote)" :href="quoteLink(quote).url" target="_blank" rel="noopener">{{ quoteLink(quote).children[0]?.text }}</a>
                            </p>
                          </div>
                        </div>
                        <br />
                        <h3>Pourquoi l'Aide Externe est Importante</h3>
                        <section v-if="article?.help?.length">
                            <div v-for="(paragraph, index) in article.help" :key="index">
                              <p v-if="paragraph.type === 'paragraph'">{{ paragraph.children[0]?.text }}</p>
                            </div>
                        </section>

                        <!-- Dynamic FAQ Section -->
                        <div class="mt-40 faq-style-one dark">
                          <h3 class="mb-30">Questions Communes</h3>
                          <div class="accordion" id="faqAccordion">
                            <!-- Loop through all FAQ items -->
                            <div v-for="(faqItem, index) in filteredFaqItems" :key="index" class="accordion-item">
                              <!-- This renders the question part -->
                              <h2 class="accordion-header" :id="'heading' + index">
                                <button
                                  class="accordion-button"
                                  type="button"
                                  :data-bs-toggle="'collapse'"
                                  :data-bs-target="'#collapse' + index"
                                  :aria-expanded="index === 0 ? 'true' : 'false'"
                                  :class="{ collapsed: index !== 0 }"
                                  :aria-controls="'collapse' + index">
                                  {{ faqItem.children[0]?.text }} <!-- FAQ Question -->
                                </button>
                              </h2>
                              <div
                                :id="'collapse' + index"
                                class="accordion-collapse collapse"
                                :class="{ show: index === 0 }"
                                :aria-labelledby="'heading' + index"
                                data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                  <!-- Render the answer for the corresponding question -->
                                  <p v-if="faqAnswers[index]">
                                    {{ faqAnswers[index]?.children[0]?.children[0]?.text }} <!-- FAQ Answer -->
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                    </div>

                    <div class="col-xl-4 col-lg-5 mt-md-120 mt-xs-50 services-sidebar">
                        <!-- Single Widget -->
                        <div class="single-widget services-list-widget">
                            <h4 class="widget-title">Articles & Informations</h4>
                            <div class="content">
                                <ul>
                                  <li v-for="item in articleLinks" :key="item.href" :class="{ 'current-item': isActive(item.href) }">
                                      <router-link :to="item.href">{{ item.text }}</router-link>
                                  </li>
                                </ul>
                            </div>
                        </div>
                        <!-- End Single Widget -->
                        <div class="single-widget bg-dark quick-contact-widget text-light ctabk">
                            <div class="content">
                                <h3>Besoin d'aide ?</h3>
                                <p>
                                  Vous avez des questions ou besoin d'un coup de pouce pour démarrer ? Contactez-moi, je suis là pour vous guider à chaque étape !
                                </p>
                                <h2><a href="tel:+33564720680">+33 5 64 72 06 80</a></h2>
                                <!--<h4><a href="mailto:info@digital.com">info@digital.com</a></h4>-->
                                <a class="btn mt-30 circle btn-sm btn-gradient" href="/contact">Prenons Contact</a>
                            </div>
                        </div>
                        <!-- Single Widget -->
                        <div class="single-widget widget-brochure">
                            <h4 class="widget-title">Télécharger</h4>
                            <ul>
                                <li><a href="/assets/pdf/CV-FR-MichaelJamesPorter.pdf" target="_blank"><i class="fas fa-file-pdf"></i> Mon CV en français</a></li>
                                <li><a href="/assets/pdf/CV-EN-MichaelJamesPorter.pdf" target="_blank"><i class="fas fa-file-pdf"></i> Mon CV en anglais</a></li>
                            </ul>
                        </div>
                        <!-- End Single Widget -->
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- End Services Details Area -->

    <CookieConsent />
  <SiteFooter />
</template>

<script>
  import TopBar from '@/components/TopBar.vue';
  import SiteHeader from '@/components/SiteHeader.vue';
  import BreadCrumbs from '@/components/BreadCrumbs.vue';
  import CookieConsent from '@/components/CookieConsent.vue';
  import SiteFooter from '@/components/SiteFooter.vue';
  import axios from 'axios';
  
  export default {
    name: 'ArticleHowTo',
    components: {
      TopBar,
      SiteHeader,
      BreadCrumbs,
      CookieConsent,
      SiteFooter,
    },
    //props: ['article'],
    computed: {
      // Filter out introduction quotes
      filteredIntroQuotes() {
        return this.article?.introduction?.filter(item => item.type === 'quote') || [];
      },
      // Filter out strategy quotes
      filteredStrategyQuotes() {
        return this.article?.strategies?.filter(item => item.type === 'quote') || [];
      },
      // Filter out FAQ items that are questions
      filteredFaqItems() {
        return this.article?.faq[0]?.children?.filter(item => item.type === 'list-item') || [];
      },
      // Filter out FAQ answers which are list with indentLevel === 1
      faqAnswers() {
        return this.article?.faq[0]?.children?.filter(item => item.type === 'list' && item.indentLevel === 1) || [];
      },
    },
    methods: {
      // Return text parts of quotes
      filteredQuoteText(quote) {
        return quote.children.filter(child => child.type === 'text' && child.text.trim());
      },
      // Return the link from a quote if present
      quoteLink(quote) {
        return quote.children.find(child => child.type === 'link');
      },
      isActive(href) {
        return this.$route.path === href;
      },
      /*
      updateMetaInfo() {
        this.$meta().refresh(); // Refresh to apply the new meta
      }*/
    },
    data() {
      return {
        article: null, 
        imageUrl: '',
        articleLinks: [
          { href: '/startups/article/startups-stade-de-developpement', text: 'Startup (Débuts)' },
          { href: '/startups/article/startups-stade-preuve-de-concept', text: 'Startup (Preuve de Concept)' },
          { href: '/startups/article/startups-stade-de-croissance', text: 'Startup (Croissance)' },
          { href: '/startups/article/bien-choisir-son-cto', text: 'Bien choisir son CTO' },
          { href: '/startups/article/bien-choisir-son-cpo', text: 'Bien choisir son CPO' },
          { href: '/startups/article/2-en-1-ctpo', text: '2-en-1 (CTPO) ?' },
          { href: '/startups/article/cto-codeur-a-batisseur-d-equipe', text: 'De Codeur à Bâtisseur d\'Équipe' },
          { href: '/startups/article/succes-et-enjeux-comment-transformer-inquietudes-en-opportunites', text: 'Succès & Enjeux' },
          { href: '/startups/article/premieres-etapes', text: 'Les Premières Etapes' },
          { href: '/startups/article/vision-vs-execution', text: 'La Vision vs l\'Exécution' },
          { href: '/startups/article/grandir-en-confiance', text: 'Grandir en Confiance' },
          { href: '/startups/article/innover-avec-avenir-en-tete', text: 'Innover avec l\'Avenir en Tête' },
          { href: '/startups/article/competence-des-equipes', text: 'Compétence des Equipes' }
        ]
      };
    },
    async created() {
      const slug = this.$route.params.slug;
      try {
        const response = await axios.get('https://transferstation.ealliance.trade:1338/api/articles', {
          params: {
            'filters[slug][$eq]': slug,
            populate: '*',
          },
          headers: {
            Authorization: `Bearer f665fa877cc16eae8d3eb504a50e76c61c6a53d31da77957d823cda87428c512946895a215f7c0b44e776f04fc9ed82ac04860fda42c441300d9479bf544166297dbb7fa9fdbcc2ac5c8ddaf603f55958d576ece59251800a9b00fff8d9387ca82ea24fc537375b905fbdfd7274901a42e771360b441e6f41b43099b9b99ab77`
          }
        });
        if (response.data.data.length > 0) {
          this.article = response.data.data[0].attributes;
          if (this.article.image && this.article.image.data) {
            this.imageUrl = `https://transferstation.ealliance.trade:1338${this.article.image.data.attributes.url}`;
          }
          // Set the meta tags dynamically
          //this.updateMetaInfo();
        }
      } catch (error) {
        console.error('Error fetching article:', error);
      }
    },
    /*
    metaInfo() {
      return {
        title: this.article ? this.article.title + ' - Conseils par Michael James PORTER' : 'Conseils par Michael James PORTER',
        meta: [
          { name: 'description', content: this.article.description || 'Michael James PORTER' },
          { name: 'keywords', content: this.$route.meta.keywords || 'Consultant en startup, CTO, Chief Technology Officer, CPO, Chief Product Officer, Alignement technologique' },
          { property: 'og:title', content: this.article.title + ' - Conseils par Michael James PORTER' },
          { property: 'og:description', content: this.article.description || 'Michael James PORTER' },
          { property: 'og:image', content: '/assets/img/ogImage.jpg' },
          { property: 'og:url', content: this.$route.fullPath || 'https://michael-james-porter.com' },
          { property: 'og:type', content: 'article' },
        ]
      };
    },*/
    //watch: {
    //  article(newArticle) {
        // Refresh meta info when article data is loaded
    //    if (newArticle) {
     //     this.$meta().refresh();
    //    }
    //  }
    //},
  };
  </script>
  <style scoped>
  .default-padding {
    padding-top: 60px !important;
  }
.ctabk {
  background-image: url(@/assets/img/shape/15.png);
}
.quote-section {
  margin: 20px 0;
}
.quote {
  font-style: italic;
  background: #f9f9f9;
  border-left: 5px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
}
.quote p {
  margin: 0;
}
.quote a {
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
}
.quote a:hover {
  text-decoration: underline;
}
  </style>