<template>
    <!-- Start Why Choose Us
    ============================================= -->
    <div class="choose-us-style-one-area default-padding text-light">
        <div class="cover-bg global"></div>
        <div class="shape-left-top">
            <img src="@/assets/img/shape/17.png" alt="Shape">
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-6 pr-80">
                    <div class="choose-us-style-one">
                        <h2 class="title mb-35">À la pointe et leader en innovation</h2>
                        <ul class="list-item">
                            <li class="wow fadeInUp">
                                <h4>Pour les Startups en Phase Initiale</h4>
                                <p>
                                    Je propose des solutions sur mesure et à la pointe de la technologie pour répondre aux défis uniques des startups en phase initiale. Que vous soyez au stade de l'idée ou du prototype, je vous aide à construire une base solide pour votre produit grâce à des stratégies de développement allégées et à des conseils sur la faisabilité technique. Accélérons votre progression du concept à la réalité.
                                </p>
                            </li>
                            <li class="wow fadeInUp" data-wow-delay="300ms">
                                <h4>Pour les Startups en Croissance</h4>
                                <p>
                                    Alors que votre produit gagne en traction sur le marché, votre croissance nécessite une direction claire. Je vous aide à faire évoluer vos stratégies technologiques et produits, en assurant une évolutivité fluide, une efficacité opérationnelle, et les bonnes décisions techniques pour soutenir votre prochaine étape de croissance.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Why Choose Us -->
  </template>
  
  <script>
  export default {
    name: 'WhoSection',
  };
  </script>
  
  <style scoped>
.global {
    background-image: url(@/assets/img/global.jpg);
}
  </style>
  