  <template>
    <TopBar />
    <SiteHeader />
    <BreadCrumbs />
    <ContactSection />
    <CookieConsent />
    <SiteFooter />
  </template>
  
  <script>
    import TopBar from '@/components/TopBar.vue';
    import SiteHeader from '@/components/SiteHeader.vue';
    import BreadCrumbs from '@/components/BreadCrumbs.vue';
    import ContactSection from '@/components/ContactSection.vue';
    import CookieConsent from '@/components/CookieConsent.vue';
    import SiteFooter from '@/components/SiteFooter.vue';
    export default {
      name: 'ContactPage',
      components: {
        TopBar,
        SiteHeader,
        BreadCrumbs,
        ContactSection,
        CookieConsent,
        SiteFooter,
      },
      metaInfo() {
        return {
          title: this.$route.meta.title || 'Michael James PORTER',
          meta: [
            { name: 'description', content: this.$route.meta.description || 'Michael James PORTER' },
            { name: 'keywords', content: this.$route.meta.keywords || 'Consultant en startup, CTO, Chief Technology Officer, CPO, Chief Product Officer, Alignement technologique' },
            { property: 'og:title', content: this.$route.meta.ogTitle || 'Michael James PORTER' },
            { property: 'og:description', content: this.$route.meta.ogDescription || 'Michael James PORTER' },
            { property: 'og:image', content: this.$route.meta.ogImage || '/assets/img/ogImage.jpg' },
            { property: 'og:url', content: this.$route.meta.ogUrl || 'https://michael-james-porter.com' },
            { property: 'og:type', content: 'website' },
          ]
        };
      }
    };
  </script>
  