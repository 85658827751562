<template>
  <footer class="bg-dark text-light">
    <div class="footer-shape">
      <div class="item"><img src="@/assets/img/shape/7.png" alt="Shape"></div>
      <div class="item"><img src="@/assets/img/shape/9.png" alt="Shape"></div>
    </div>
    <div class="container">
      <div class="relative f-items pt-70 pb-120 pt-xs-0 pb-xs-50">
        <div class="row">
          <div class="col-lg-4 col-md-6 footer-item pr-50 pr-xs-15">
            <div class="f-item about">
              <a href="/">
                <img class="logo" src="@/assets/img/logo-light.png" alt="Logo">
              </a>
              <p>Toujours disponible pour un bon café et un échange d'idées.</p>
              
              <div class="opening-hours">
                <h5>Fuseaux horaires</h5>
                <ul>
                  <li> 
                      <div class="working-day">CET / CEST</div>
                      <div class="marker"></div>
                      <div class="working-hour">En super forme</div>
                  </li>
                  <li>
                      <div class="working-day">+/- 6 heures</div>
                      <div class="marker"></div>
                      <div class="working-hour">Encore faisable</div>
                  </li>
                  <li>
                      <div class="working-day">+/- 12 heures</div>
                      <div class="marker"></div>
                      <div class="working-hour">MEH</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          <div class="col-lg-2 col-md-6 footer-item">
            <div class="f-item link">
              <h4 class="widget-title">Startups</h4>
              <ul>
                <li>
                    <router-link to="/startups/article/startups-stade-de-developpement">
                        {{ $t('nav.startups.early') }}
                    </router-link>
                </li>
                <li>
                    <router-link to="/startups/article/startups-stade-preuve-de-concept">
                        {{ $t('nav.startups.poc') }}
                    </router-link>
                </li>
                <li>
                    <router-link to="/startups/article/startups-stade-de-croissance">
                        {{ $t('nav.startups.scale') }}
                    </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-6 footer-item">
            <div class="f-item link">
              <h4 class="widget-title">Bien choisir</h4>
              <ul>
                <li>
                    <router-link to="/startups/article/bien-choisir-son-cto">
                        {{ $t('nav.finding.cto') }}
                    </router-link>
                </li>
                <li>
                    <router-link to="/startups/article/bien-choisir-son-cpo">
                        {{ $t('nav.finding.cpo') }}
                    </router-link>
                </li>
                <li>
                    <router-link to="/startups/article/2-en-1-ctpo">
                        {{ $t('nav.finding.ctpo') }}
                    </router-link>
                </li>
                <li>
                    <router-link to="/startups/article/cto-codeur-a-batisseur-d-equipe">
                        De Codeur à Bâtisseur d'Équipe
                    </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-6 footer-item">
            <div class="f-item link">
              <h4 class="widget-title">Articles</h4>
              <ul>
                <li>
                  <router-link to="/startups/article/succes-et-enjeux-comment-transformer-inquietudes-en-opportunites">
                    Succès & Enjeux
                  </router-link>
                </li>
                <li>
                  <router-link to="/startups/article/premieres-etapes">
                    Les Premières Etapes
                  </router-link>
                </li>
                <li>
                  <router-link to="/startups/article/vision-vs-execution">
                    La Vision vs l'Exécution
                  </router-link>
                </li>
                <li>
                  <router-link to="/startups/article/grandir-en-confiance">
                    Grandir en Confiance
                  </router-link>
                </li>
                <li>
                  <router-link to="/startups/article/innover-avec-avenir-en-tete">
                    Innover avec l'Avenir en Tête
                  </router-link>
                </li>
                <li>
                  <router-link to="/startups/article/competence-des-equipes">
                    Compétence des Equipes
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Start Footer Bottom -->
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <p>© 2024 Michael James Porter. All rights reserved.</p>
                </div>
                <div class="col-lg-6 text-end">
                    <ul>
                        <li>
                            <a href="/legal/terms">Terms</a>
                        </li>
                        <li>
                            <a href="/legal/privacy">Privacy</a>
                        </li>
                        <li>
                            <a href="/contact">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- End Footer Bottom -->
  </footer>
</template>
  
  <script>
  export default {
    name: 'SiteFooter',
    mounted() {
        // If you need to access Wow.js directly, you can do so via this.$wow
        //this.$wow.sync(); // For example, to sync new elements after DOM changes
    },
  };
  </script>
  
  <style scoped>
  .col-lg-2 {
    width: 22%;
    min-width: 160px;
  }
  </style>
  