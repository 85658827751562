<template>
    <div v-if="!consentGiven" class="cookie-consent-popup">
      <p>
        Ce site utilise des cookies pour améliorer votre expérience utilisateur. En poursuivant votre navigation, vous acceptez l'utilisation des cookies conformément à notre
        <a href="/legal/privacy" target="_blank">politique de confidentialité</a>.
      </p>
      <div class="cookie-consent-buttons">
        <button @click="acceptCookies">Accepter</button>
        <button @click="declineCookies">Refuser</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        consentGiven: false
      };
    },
    mounted() {
      this.checkCookieConsent();
    },
    methods: {
      checkCookieConsent() {
        const consent = localStorage.getItem('cookieConsent');
        this.consentGiven = consent === 'accepted';
      },
      acceptCookies() {
        localStorage.setItem('cookieConsent', 'accepted');
        this.consentGiven = true;
        // You can add additional logic here to enable tracking scripts if needed
      },
      declineCookies() {
        localStorage.setItem('cookieConsent', 'declined');
        this.consentGiven = true;
        // You can add additional logic here to block tracking scripts if needed
      }
    }
  };
  </script>
  
  <style scoped>
  .cookie-consent-popup {
    position: fixed;
    bottom: 20px;
    left: 20px;
    right: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
  }
  .cookie-consent-popup p {
    margin: 0;
    font-size: 14px;
  }
  .cookie-consent-buttons {
    margin-top: 10px;
  }
  .cookie-consent-buttons button {
    margin-right: 10px;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
  }
  </style>
  