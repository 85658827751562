import { createApp } from 'vue';
import { createMetaManager } from 'vue-meta';
import App from './App.vue';
import router from './router';
import i18n from './i18n';

import '@/assets/css/bootstrap.min.css';
import '@/assets/css/font-awesome.min.css';
//import '@/assets/css/themify-icons.css';
import '@/assets/css/elegant-icons.css';
import '@/assets/css/flaticon-set.css';
import '@/assets/css/magnific-popup.css';
import '@/assets/css/swiper-bundle.min.css';
import '@/assets/css/animate.css';
import '@/assets/css/validnavs.css';
import '@/assets/css/helper.css';
import '@/assets/css/unit-test.css';
import '@/assets/css/style.css';

const app = createApp(App);

// Install vue-meta manager
const metaManager = createMetaManager();
app.use(metaManager);

app.use(i18n);
app.use(router).mount('.wrapper');

// Patch for ResizeObserver loop limit exceeded error
if (typeof ResizeObserver !== 'undefined') {
    const resizeObserverErr = window.console.error;
    window.console.error = (...args) => {
      if (args[0].includes('ResizeObserver loop limit exceeded')) {
        return;  // Suppress the error
      }
      resizeObserverErr(...args);  // Log other errors normally
    };
  }