<template>
        <!-- Header 
    ============================================= -->
    <header>
        <!-- Start Navigation -->
        <nav class="navbar mobile-sidenav navbar-style-one navbar-sticky navbar-default validnavs white navbar-fixed no-background">

            <div class="container">
                <div class="row align-center">

                    <!-- Start Header Navigation -->
                    <div class="col-xl-2 col-lg-3 col-md-2 col-sm-1 col-1">
                        <div class="navbar-header">
                            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                                <i class="fa fa-bars"></i>
                            </button>
                            <router-link to="/" class="navbar-brand">
                                <img src="@/assets/img/logo.png" class="logo" alt="Logo">
                            </router-link>
                        </div>
                    </div>
                    <!-- End Header Navigation -->

                    <div class="col-xl-6 offset-xl-1 col-lg-6 col-md-4 col-sm-4 col-4">
                        <div class="collapse navbar-collapse" id="navbar-menu">
                            <!-- Logo with link to the home page -->
                            <router-link to="/">
                                <img src="@/assets/img/logo.png" alt="Logo">
                            </router-link>
                            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                                <i class="fa fa-times"></i>
                            </button>
                            
                            <NavBar />
                            
                        </div>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-7 col-7">
                        <div class="attr-right">
                            <!-- Start Atribute Navigation -->
                            <div class="attr-nav">
                                <ul>
                                    <li class="button">
                                        <router-link to="/contact">{{ $t('header.lets-talk') }}</router-link>
                                    </li>
                                </ul>
                            </div>
                            <!-- End Atribute Navigation -->
        
                        </div>
                    </div>

                </div>         
                <!-- Main Nav -->

                <!-- Overlay screen for menu -->
                <div class="overlay-screen"></div>
                <!-- End Overlay screen for menu -->
            </div>   
        </nav>
        <!-- End Navigation -->
    </header>
    <!-- End Header -->
</template>
  
  <script>
  import NavBar from '@/components/NavBar.vue';
  export default {
    name: 'SiteHeader',
    components: {
      NavBar,
    },
  };
  </script>
  
  <style scoped>

  </style>
  