<template>
  <div class="about-style-one-area default-padding">
    <div class="container">
      <div class="row align-center">
        <div class="about-style-one col-xl-6 col-lg-5">
          <div class="h4 sub-heading">{{ $t('about.h1') }}</div>
          <h2 class="title mb-25">{{ $t('about.h2') }}</h2>
          <p>{{ $t('about.text') }}</p>
          <div class="owner-info">
            <div class="left-info">
              <h4>{{ $t('about.name') }}</h4>
              <span>{{ $t('about.title') }}</span>
            </div>
            <div class="right-info">
              <img src="@/assets/img/signature.png" alt="Image Not Found">
            </div>
          </div>
        </div>
        <div class="about-style-one col-xl-5 offset-xl-1 col-lg-6 offset-lg-1">
          <div class="about-thumb">
            <img class="wow fadeInRight" src="@/assets/img/success.jpg" alt="Image Not Found">
            <div class="about-card wow fadeInUp" data-wow-delay="500ms">
              <ul>
                <li>
                  <div class="icon">
                    <i class="flaticon-license"></i>
                  </div>
                  <div class="fun-fact">
                    <div class="counter">
                      <div class="timer1">22</div>
                      <div class="operator">+</div>
                    </div>
                    <span class="medium">{{ $t('about.years-text') }}</span>
                  </div>
                </li>
                <li>
                  <div class="icon">
                    <i class="flaticon-global"></i>
                  </div>
                  <div class="fun-fact">
                    <div class="counter">
                      <div class="timer1">40</div>
                      <div class="operator">+</div>
                    </div>
                    <span class="medium">{{ $t('about.clients-text') }}</span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="thumb-shape-bottom wow fadeInDown" data-wow-delay="300ms">
              <img src="@/assets/img/shape/anim-3.png" alt="Image Not Found">
              <img src="@/assets/img/shape/anim-4.png" alt="Image Not Found">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutSection',
};
</script>

<style scoped>
.about-section {
  padding: 60px 0;
  text-align: center;
}
</style>
