<template>
                            <ul class="nav navbar-nav navbar-center" data-in="fadeInDown" data-out="fadeOutUp">
                                <!--
                                <li class="dropdown">
                                    <a href="#" class="dropdown-toggle active" data-toggle="dropdown" >Home</a>
                                    <ul class="dropdown-menu">
                                        <li><a href="index.html">Consulting Business</a></li>
                                        <li><a href="index-2.html">Corporate Business</a></li>
                                        <li><a href="marketing-agency.html">Marketing Agency</a></li>
                                        <li><a href="insurance.html">Insurance</a></li>
                                        <li><a href="solar-energy.html">Solar Energy</a></li>
                                        <li><a href="software-landing.html">Software Landing</a></li>
                                    </ul>
                                </li>
                                <li class="dropdown">
                                    <a href="#" class="dropdown-toggle" data-toggle="dropdown" >Pages</a>
                                    <ul class="dropdown-menu">
                                        <li><a href="about-us.html">About Us</a></li>
                                        <li><a href="team.html">Team</a></li>
                                        <li><a href="team-details.html">Team Details</a></li>
                                        <li><a href="pricing.html">Pricing</a></li>
                                        <li><a href="faq.html">FAQ</a></li>
                                        <li><a href="contact-us.html">Contact Us</a></li>
                                        <li><a href="404.html">Error Page</a></li>
                                    </ul>
                                </li>
                                <li class="dropdown">
                                    <a href="project.html" class="dropdown-toggle" data-toggle="dropdown" >Projects</a>
                                    <ul class="dropdown-menu">
                                        <li><a href="project.html">Project style one</a></li>
                                        <li><a href="project-details.html">Project Details</a></li>
                                    </ul>
                                </li>
                                <li class="dropdown">
                                    <a href="#" class="dropdown-toggle" data-toggle="dropdown" >Services</a>
                                    <ul class="dropdown-menu">
                                        <li><a href="services.html">Services Version One</a></li>
                                        <li><a href="services-2.html">Services Version Two</a></li>
                                        <li><a href="services-details.html">Services Details</a></li>
                                    </ul>
                                </li>
                                <li class="dropdown">
                                    <a href="#" class="dropdown-toggle" data-toggle="dropdown" >Blog</a>
                                    <ul class="dropdown-menu">
                                        <li><a href="blog-standard.html">Blog Standard</a></li>
                                        <li><a href="blog-with-sidebar.html">Blog With Sidebar</a></li>
                                        <li><a href="blog-2-colum.html">Blog Grid Two Colum</a></li>
                                        <li><a href="blog-3-colum.html">Blog Grid Three Colum</a></li>
                                        <li><a href="blog-single.html">Blog Single</a></li>
                                        <li><a href="blog-single-with-sidebar.html">Blog Single With Sidebar</a></li>
                                    </ul>
                                </li>-->
                                <li class="dropdown">
                                    <a href="#" class="dropdown-toggle" data-toggle="dropdown" >{{ $t('nav.startups.label') }}</a>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <router-link to="/startups/article/startups-stade-de-developpement">
                                                {{ $t('nav.startups.early') }}
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link to="/startups/article/startups-stade-preuve-de-concept">
                                                {{ $t('nav.startups.poc') }}
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link to="/startups/article/startups-stade-de-croissance">
                                                {{ $t('nav.startups.scale') }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </li>
                                <li class="dropdown">
                                    <a href="#" class="dropdown-toggle" data-toggle="dropdown" >{{ $t('nav.finding.label') }}</a>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <router-link to="/startups/article/bien-choisir-son-cto">
                                                {{ $t('nav.finding.cto') }}
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link to="/startups/article/bien-choisir-son-cpo">
                                                {{ $t('nav.finding.cpo') }}
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link to="/startups/article/2-en-1-ctpo">
                                                {{ $t('nav.finding.ctpo') }}
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link to="/startups/article/cto-codeur-a-batisseur-d-equipe">
                                                De Codeur à Bâtisseur d'Équipe
                                            </router-link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <router-link to="/startups/article/succes-et-enjeux-comment-transformer-inquietudes-en-opportunites">
                                        {{ $t('nav.success') }}
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/about">
                                        {{ $t('nav.about') }}
                                    </router-link>
                                </li>
                                <!--
                                <li>
                                    <router-link to="/contact">
                                        {{ $t('nav.contact') }}
                                    </router-link>
                                </li>-->
                            </ul>
</template>
  
  <script>
  export default {
    name: 'NavBar',
  };
  </script>
  
  <style scoped>

  </style>
  