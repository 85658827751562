<template>
    <!-- Start Contact Us 
    ============================================= -->
    <div class="overflow-hidden contact-style-one-area default-padding">

<div class="contact-shape">
    <img src="assets/img/shape/37.png" alt="Image Not Found">
</div>

<div class="container">
    <div class="row align-center">

        <div class="contact-stye-one col-lg-5 mb-md-50 mb-xs-20">

            <div class="contact-style-one-info">
                <h2>Laissez-vous agréablement surprendre</h2>
                <p>
                    Envie de discuter et de donner vie à vos idées ? Contactez-moi, je suis là pour rendre les choses simples et efficaces, avec une touche de bonne humeur !
                </p>
                <ul>
                    <li class="wow fadeInUp">
                        <div class="icon">
                            <i class="fas fa-phone-alt"></i>
                        </div>
                        <div class="content">
                            <h5 class="title">Appelez-moi</h5>
                            <a :href="`tel:${$t('topbar.tel')}`">{{ $t('topbar.tel') }}</a>
                        </div>
                    </li>
                    <li class="wow fadeInUp" data-wow-delay="300ms">
                        <div class="icon">
                            <i class="fas fa-map-marker-alt"></i>
                        </div>
                        <div class="info">
                            <h5 class="title">Présent à l'international</h5>
                            <p>
                                {{ $t('topbar.based-in') }}
                            </p>
                        </div>
                    </li>
                    <li class="wow fadeInUp" data-wow-delay="500ms">
                        <div class="icon">
                            <i class="fas fa-envelope-open-text"></i>
                        </div>
                        <div class="info">
                            <h5 class="title">Email officieux</h5>
                            <span id="obfuscated"></span>
                        </div>
                    </li>
                    <!--
                    <li class="wow fadeInUp" data-wow-delay="500ms">
                        <div class="icon">
                            <i class="fas fa-calendar-alt"></i>
                        </div>
                        <div class="info">
                            <h5 class="title">Réservez une réunion avec moi</h5>
                            <p>
                                Link to availabilities
                            </p>
                        </div>
                    </li>-->
                </ul>
            </div>
        </div>
        
        <div class="contact-stye-one col-lg-7 pl-60 pl-md-15 pl-xs-15">
            <div class="contact-form-style-one">
                <h5 class="sub-title">Des questions ?</h5>
                <h2 class="heading">Envoyez-moi un message</h2>
                <form @submit.prevent="submitForm" class="contact-form">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <input class="form-control" v-model="formData.name" id="name" name="name" placeholder="Nom" type="text">
                                <span class="alert-error"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <input class="form-control" v-model="formData.email" id="email" name="email" placeholder="Email*" type="email" required>
                                <span class="alert-error"></span>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <input class="form-control" v-model="formData.phone" id="phone" name="phone" placeholder="Téléphone" type="text">
                                <span class="alert-error"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group comments">
                                <textarea class="form-control" v-model="formData.comments" id="comments" name="comments" placeholder="Parlez-moi de votre projet *" required></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <button type="submit" name="submit" id="submit">
                                <i class="fa fa-paper-plane"></i> Prendre contact
                            </button>
                        </div>
                    </div>
                    <!-- Alert Message -->
                    <div class="col-lg-12 alert-notification">
                        <div id="message" class="alert-msg"></div>
                        <div v-if="formSent" class="alert alert-success">Message sent successfully!</div>
                        <div v-if="formError" class="alert alert-danger">Error sending message. Please try again later.</div>
                    </div>
                </form>
            </div>
        </div>

        

    </div>
</div>
</div>
<!-- End Contact -->

<!-- Start Map 
============================================= 
<div class="overflow-hidden maps-area bg-gray">
<div class="google-maps">
  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d11335123.175524829!2d6.299202627278921!3d46.207720412055274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sfr!4v1725299584690!5m2!1sen!2sfr"></iframe>
</div>
</div>
 End Map -->
  </template>
  
  <script>
import axios from 'axios';
  export default {
    name: 'ContactSection',
    data() {
        return {
        formData: {
            name: '',
            email: '',
            phone: '',
            comments: '',
        },
        formSent: false,
        formError: false,
        };
    },
    mounted() {
        this.initEmailObfuscation();
    },
    methods: {
        async submitForm() {
            const brevoAPIKey = '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'; // Replace with your Brevo API key

            const emailData = {
                sender: { name: this.formData.name, email: 'mike@ealliance.trade' },
                to: [{ email: 'mike@mindlabs.pro', name: 'Mike' }], // The email you're sending to
                subject: 'New Contact Form Submission',
                htmlContent: `
                <h2>New Contact Form Submission</h2>
                <p><strong>Name:</strong> ${this.formData.name}</p>
                <p><strong>Email:</strong> ${this.formData.email}</p>
                <p><strong>Phone:</strong> ${this.formData.phone}</p>
                <p><strong>Message:</strong> ${this.formData.comments}</p>
                `,
            };

            try {
                await axios.post('https://api.brevo.com/v3/smtp/email', emailData, {
                headers: {
                    'api-key': brevoAPIKey,
                    'Content-Type': 'application/json',
                },
                });
                this.formSent = true; // Show success message
                this.formError = false;
                this.resetForm();
            } catch (error) {
                this.formError = true; // Show error message
                this.formSent = false;
                console.error('Error sending email:', error);
            }
        },
        resetForm() {
            this.formData.name = '';
            this.formData.email = '';
            this.formData.phone = '';
            this.formData.comments = '';
        },
        initEmailObfuscation() {
            const user = "spam-trap";
            const domain = "michael-james-porter.com";

            // Get the localized subject from the translations
            const subject = this.$t('email-subject');

            const email = `${user}@${domain}`;
            const emailElement = document.getElementById('obfuscated');

            if (emailElement) {
                // Create a mailto link with subject
                const mailtoLink = document.createElement('a');
                mailtoLink.href = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
                mailtoLink.textContent = email;

                // Append the link to the span
                emailElement.appendChild(mailtoLink);
            }
        },
    },
  };
  </script>
  
  <style scoped>

  </style>
  