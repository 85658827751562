<template>
    <!-- Start Process
    ============================================= -->
    <div class="text-center process-style-one-area default-padding">
        <div class="large-shape">
            <img src="@/assets/img/shape/11.png" alt="Shape">
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-8 offset-lg-2">
                    <div class="text-center site-heading">
                        <h4 class="sub-heading">{{ $t('process.subHeading') }}</h4>
                        <h2 class="title">{{ $t('process.title') }}</h2>
                        <div class="devider"></div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="container">
            <div class="row">
                <!-- Single Item -->
                <div class="col-lg-4">
                    <div class="process-style-one active">
                        <div class="thumb">
                            <img src="@/assets/img/step1.jpg" alt="Thumb">
                            <span>01</span>
                        </div>
                        <h4>{{ $t('process.steps.step1.title') }}</h4>
                        <p>{{ $t('process.steps.step1.description') }}</p>
                    </div>
                </div>
                <!-- End Single Item -->
                <!-- Single Item -->
                <div class="col-lg-4">
                    <div class="process-style-one">
                        <div class="thumb">
                            <img src="@/assets/img/step2.jpg" alt="Thumb">
                            <span>02</span>
                        </div>
                        <h4>{{ $t('process.steps.step2.title') }}</h4>
                        <p>{{ $t('process.steps.step2.description') }}</p>
                    </div>
                </div>
                <!-- End Single Item -->
                <!-- Single Item -->
                <div class="col-lg-4">
                    <div class="process-style-one">
                        <div class="thumb">
                            <img src="@/assets/img/step3.jpg" alt="Thumb">
                            <span>03</span>
                        </div>
                        <h4>{{ $t('process.steps.step3.title') }}</h4>
                        <p>{{ $t('process.steps.step3.description') }}</p>
                    </div>
                </div>
                <!-- End Single Item -->
            </div>
        </div>
    </div>
    <!-- End Process -->
</template>
  
  <script>
  export default {
    name: 'ProcessSection',
  };
  </script>
  
  <style scoped>

  </style>
  