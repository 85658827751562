import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import AboutPage from '@/views/AboutPage.vue';
import ContactPage from '@/views/ContactPage.vue';
import ArticleHowTo from '@/views/ArticleHowTo.vue';
import LegalPages from '@/views/LegalPages.vue';

const routes = [
  { path: '/', name: 'Home', component: HomePage,
    meta: {
      title: 'Leader en Technologie et Stratégie CTO – Michael James Porter',
      description: 'Michael James Porter accompagne votre startup dans l\'alignement stratégique et technologique pour une croissance durable.',
      keywords: 'Michael James Porter, consulting, Consultant en startup, CTO, Chief Technology Officer, CPO, Chief Product Officer, Croissance durable, Technologie startups',
      ogTitle: 'Leader en Technologie et Stratégie CTO – Michael James Porter',
      ogDescription: 'Michael James Porter accompagne votre startup dans l\'alignement stratégique et technologique pour une croissance durable.',
      ogImage: '/assets/img/ogImage.jpg',
      ogUrl: 'https://michael-james-porter.com/',
    },
    alias: ['/accueil', '/home', '/inicio'] },
  { path: '/about', name: 'About', component: AboutPage,
    meta: {
      title: 'À Propos | Michael James Porter, CTO et Leader en Technologie',
      description: 'Découvrez Michael James Porter, CTO. Expert en stratégie technologique et croissance durable pour startups.',
      keywords: 'Michael James Porter, consulting, Consultant en startup, CTO, Chief Technology Officer, CPO, Chief Product Officer, Croissance durable, Technologie startups',
      ogTitle: 'À Propos | Michael James Porter, CTO et Leader en Technologie',
      ogDescription: 'Découvrez Michael James Porter, CTO. Expert en stratégie technologique et croissance durable pour startups.',
      ogImage: '/assets/img/ogImage.jpg',
      ogUrl: 'https://michael-james-porter.com/about',
    },
    alias: ['/a-propos', '/sobre-mi'] },
  { path: '/contact', name: 'Contact', component: ContactPage,
    meta: {
      title: 'Contactez Michael James Porter',
      description: 'Prenez contact avec Michael James Porter pour un accompagnement sur mesure en stratégie technologique et leadership CTO. Optimisez la croissance de votre startup.',
      keywords: 'Michael James Porter, consulting, Consultant en startup, CTO, Chief Technology Officer, CPO, Chief Product Officer, Croissance durable, Technologie startups',
      ogTitle: 'Contactez Michael James Porter',
      ogDescription: 'Prenez contact avec Michael James Porter pour un accompagnement sur mesure en stratégie technologique et leadership CTO. Optimisez la croissance de votre startup.',
      ogImage: '/assets/img/ogImage.jpg',
      ogUrl: 'https://michael-james-porter.com/contact',
    },
    alias: ['/contactez-moi', '/contactame'] },

  // Dynamic generic route for articles
  { 
    path: '/startups/article/:slug', 
    name: 'Article', 
    component: ArticleHowTo,
    props: true,
    meta: {
      title: 'Article | Conseils par Michael James Porter',
      description: 'Apprenez comment développer votre startup grâce aux conseils d\'experts en stratégie technologique et croissance durable. Découvrez des solutions adaptées à chaque phase de votre entreprise.',
      keywords: 'Michael James Porter, consulting, Consultant en startup, CTO, Chief Technology Officer, CPO, Chief Product Officer, Croissance durable, Technologie startups',
      ogTitle: 'Article | Conseils par Michael James Porter',
      ogDescription: 'Apprenez comment développer votre startup grâce aux conseils d\'experts en stratégie technologique et croissance durable. Découvrez des solutions adaptées à chaque phase de votre entreprise.',
      ogImage: '/assets/img/ogImage.jpg',
      ogUrl: '/startups/article/:slug',
    },
  },

  // Dynamic generic route for legals
  { 
    path: '/legal/:slug', 
    name: 'Legal', 
    component: LegalPages,
    props: true,
    meta: {
      title: 'Michael James Porter',
      description: 'Informations légales.',
      keywords: 'Michael James Porter, consulting, Consultant en startup, CTO, Chief Technology Officer, CPO, Chief Product Officer, Croissance durable, Technologie startups',
      ogTitle: 'Michael James Porter',
      ogDescription: 'Informations légales.',
      ogImage: '/assets/img/ogImage.jpg',
      ogUrl: '/startups/article/:slug',
    },
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {

  // Set meta data dynamically before page reload
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  // Update other meta tags
  const metaTags = [
    { name: 'description', content: to.meta.description },
    { name: 'keywords', content: to.meta.keywords },
    { property: 'og:title', content: to.meta.ogTitle },
    { property: 'og:description', content: to.meta.ogDescription },
    { property: 'og:image', content: to.meta.ogImage },
    { property: 'og:url', content: to.meta.ogUrl }
  ];

  metaTags.forEach((meta) => {
    if (meta.content) {
      let element = document.querySelector(`meta[name="${meta.name}"], meta[property="${meta.property}"]`);
      if (!element) {
        element = document.createElement('meta');
        if (meta.name) {
          element.setAttribute('name', meta.name);
        }
        if (meta.property) {
          element.setAttribute('property', meta.property);
        }
        document.head.appendChild(element);
      }
      element.setAttribute('content', meta.content);
    }
  });

  // Force full page reload by redirecting via window.location
  if (to.fullPath !== window.location.pathname) {
    window.location.href = to.fullPath;
  } else {
    next();
  }
});

/*
router.afterEach((to) => {
  console.log('Navigating to:', to.path);  // Debugging
  console.log('Meta data:', to.meta);  // Debugging
});
*/

export default router;
